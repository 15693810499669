import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Ever-Increasing Risk of Hurricanes and Tidal Floods, and a Solution`}</p>
    <p>{`According to the United States Search and Rescue Force (USSRTF)`}<br parentName="p"></br>{`
`}{`“Each year, on average, 10 tropical storms, of which six become hurricanes, develop over the Atlantic Ocean, the Caribbean Sea, or the Gulf of Mexico from June to November.” (Undated). In 2005 a large category 5 hurricane called Hurricane Katrina hit the city of New Orleans and surrounding areas. It killed over 1,800 people and caused about 161 billion dollars in damage. (DoSomething.org, 2014). The power of both hurricanes and tropical storms has been increasing since the 1980s, as shown in figure one below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5789ee840563987116f0977ee91989c/b99c8/image6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHtwobA/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERMQL/2gAIAQEAAQUC0w5dTJRH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAECAhIv/aAAgBAQAGPwJNUf/EABgQAAMBAQAAAAAAAAAAAAAAAAABESFB/9oACAEBAAE/IXeojOzQKVSNtCUsP//aAAwDAQACAAMAAAAQd8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAxIUFRcZHB/9oACAEBAAE/EMlHwXCxnIgrX2KXbgMFUxuACEO6WAevI5FHP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image6",
            "title": "image6",
            "src": "/static/e5789ee840563987116f0977ee91989c/e5166/image6.jpg",
            "srcSet": ["/static/e5789ee840563987116f0977ee91989c/f93b5/image6.jpg 300w", "/static/e5789ee840563987116f0977ee91989c/b4294/image6.jpg 600w", "/static/e5789ee840563987116f0977ee91989c/e5166/image6.jpg 1200w", "/static/e5789ee840563987116f0977ee91989c/b99c8/image6.jpg 1612w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Figure One, (NCA, 2014)`}</em></p>
    <p>{`As evident from this graph, the rate of change is most probable to continue going upwards in a linear fashion for at least ten or so more years, on the other hand, it is also possible that this could change into an exponential curve, the power getting worse and worse as time goes on. This means that the power of hurricanes will continue this trend if we don’t do anything. The repercussions of tropical storms or hurricanes will be tragic wherever it hits and to the areas surrounding it.`}</p>
    <p>{`Another pressing matter is the rapidly rising rate of tidal floods due to the sea levels rising (NOAA, 2020), this can be seen in figure 2.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5789ee840563987116f0977ee91989c/b99c8/image6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHtwobA/8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERMQL/2gAIAQEAAQUC0w5dTJRH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAECAhIv/aAAgBAQAGPwJNUf/EABgQAAMBAQAAAAAAAAAAAAAAAAABESFB/9oACAEBAAE/IXeojOzQKVSNtCUsP//aAAwDAQACAAMAAAAQd8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAdEAACAgIDAQAAAAAAAAAAAAABEQAxIUFRcZHB/9oACAEBAAE/EMlHwXCxnIgrX2KXbgMFUxuACEO6WAevI5FHP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image6",
            "title": "image6",
            "src": "/static/e5789ee840563987116f0977ee91989c/e5166/image6.jpg",
            "srcSet": ["/static/e5789ee840563987116f0977ee91989c/f93b5/image6.jpg 300w", "/static/e5789ee840563987116f0977ee91989c/b4294/image6.jpg 600w", "/static/e5789ee840563987116f0977ee91989c/e5166/image6.jpg 1200w", "/static/e5789ee840563987116f0977ee91989c/b99c8/image6.jpg 1612w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><br parentName="p"></br>{`
`}<em parentName="p">{`Figure 2 (NOAA, 2015)`}</em></p>
    <p>{`Shown in this graph is the relationship between the relative sea level and the US High Tide Flooding (also known as ‘Sunny Day’ flooding). This shows a direct correlation between the increase in sea levels and the increase in Flood Days per Year.`}<br parentName="p"></br>{`
`}{`If we allow these trends to continue, some areas of Miami and other major coastal cities may go underwater as soon as 2050 (Dobson, 2019). At best, there will be constant flooding and a looming threat of another ‘Hurricane Katrina’ size storm.`}<br parentName="p"></br>{`
`}{`It is necessary that at this point we take climate change extremely seriously and begin the development of a solution. As an Urban Planner, I will take into account the effects of any proposed changes that could impact peoples’ living and working conditions, inside of Miami Dade County and other areas within Miami. Therefore, I will next explain new zoning codes, transportation, and energy measures. This will be called the Three-Pronged Approach.`}</p>
    <p>{`###Prong 1: Zoning Codes`}</p>
    <p>{`If one looks at a map of Miami, they would see miles of the Everglades without any human population. Miami was built on a thin limestone ridge that sits a few feet above the ocean. However, in the hundred or so years since Miami was established, builders have slowly pushed westwards towards the Everglades. They do this through landfill and swamp drainage. The results of this are a lowered, and very distressed water table that the city of Miami depends on. Furthermore, this deteriorates the Everglades’ ecosystem due to the Urban Sprawl expansion. This expansion is linked to the traffic nightmare that residents of Miami experience every day (Brunner, Undated).`}<br parentName="p"></br>{`
`}{`The solution to Urban Sprawl has already been implemented, it is called the Urban Development Boundary, or UDB. This will place a limit on how close to the Everglades buildings can be built.`}<br parentName="p"></br>{`
`}{`However, the UDB has seen extreme push-back by real-estate developers and rock miners. And since 1967 we’ve seen an increase of 52 square miles to the UDB. (For perspective that’s twice the size of the city of Manhattan).`}<br parentName="p"></br>{`
`}{`In this image, one can see how the UDB has been pushed back over time. (The colored areas represent the area added to the UDB to accommodate increasing real-estate and land)`}<br parentName="p"></br>{`
`}{`We need to respect the UDB and leave it in place. Further push-back will destroy more areas of the Everglades and risk the destruction of our water table.`}<br parentName="p"></br>{`
`}{`Furthermore, I believe we should adopt new zoning codes to regulate building in areas that are not at risk of projected sea-level rise. Once we have successfully combated sea-level rise, we can make those areas available again as long as they are not areas within the Everglades or are dangerous to build on.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "808px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/401ecc816e51b76a05aa6983404db5ca/b2462/image3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "143.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAdABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB93mmwaDDSbgH/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAECETEDQUL/2gAIAQEAAQUCeRdxo5LtRKJs8rFFM6P/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAACAwEAAAAAAAAAAAAAAAABEAARITH/2gAIAQEABj8CloB50QK3/8QAHBAAAgMBAAMAAAAAAAAAAAAAAAERITFRQYGR/9oACAEBAAE/IaehBtok4Oyc3pNSn6KC8k6TlpSLw0VpJ0hGk23TKg//2gAMAwEAAgADAAAAECMNMP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB0QAQEBAQEAAgMAAAAAAAAAAAERACExQXFRYYH/2gAIAQEAAT8QA6HSvNafjvN1L67JmXJ2hhNB5ggtcmnCr71ExQUp8j+DDZZdW9Ofrf0K7dU6YMvLDEAFGPrMcCb/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image3",
            "title": "image3",
            "src": "/static/401ecc816e51b76a05aa6983404db5ca/b2462/image3.jpg",
            "srcSet": ["/static/401ecc816e51b76a05aa6983404db5ca/f93b5/image3.jpg 300w", "/static/401ecc816e51b76a05aa6983404db5ca/b4294/image3.jpg 600w", "/static/401ecc816e51b76a05aa6983404db5ca/b2462/image3.jpg 808w"],
            "sizes": "(max-width: 808px) 100vw, 808px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><br parentName="p"></br>{`
`}{`Figure 3 (Fair use)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5a16214375c2aa76c9e2c78f7efe3ec9/b4294/image2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "128%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAaABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBBAUC/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHYQl0zYILaatKCTsP/xAAcEAEAAgMAAwAAAAAAAAAAAAABAAIDERIiMTL/2gAIAQEAAQUC3O3rG7Ft1aY/l8HHdvanpCFawNH/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARQf/aAAgBAwEBPwFOMoR//8QAFxEAAwEAAAAAAAAAAAAAAAAAARAhAv/aAAgBAgEBPwEVZi//xAAaEAACAgMAAAAAAAAAAAAAAAABEAAxERJB/9oACAEBAAY/AphX17GElVKC/8QAHRABAAICAgMAAAAAAAAAAAAAAQARITFBUXGRsf/aAAgBAQABPyFyZYsMRXXuWoKBVHbBS8wKli3U4hip94s031BCHqhUAE//2gAMAwEAAgADAAAAENvujv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8Q0GHTbAU4L//EABcRAAMBAAAAAAAAAAAAAAAAAAABMRH/2gAIAQIBAT8QfQ6UHT//xAAcEAEBAQEBAQADAAAAAAAAAAABEQAxIVFhofD/2gAIAQEAAT8QT2L73RcNQ6P77j5kYHw8yPXUAP51hy+i9h3SB95vqZUW+5s7AAcO7v8AzholcpiO4CMWoHulGXgTf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image2",
            "title": "image2",
            "src": "/static/5a16214375c2aa76c9e2c78f7efe3ec9/b4294/image2.jpg",
            "srcSet": ["/static/5a16214375c2aa76c9e2c78f7efe3ec9/f93b5/image2.jpg 300w", "/static/5a16214375c2aa76c9e2c78f7efe3ec9/b4294/image2.jpg 600w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><br parentName="p"></br>{`
`}{`Figure 4 (Fair use)`}</p>
    <p>{`In figure four (above) one can see the most likely (pink) and possible (orange) areas of flooding due to sea-level rise. We should adapt the UDB to account for these areas and make Miami safer. In the future, we may be able to push the UDB back further but for now, we need to keep Miami-Dade County as a safe place for people to reside.`}</p>
    <p>{`For the time being, a process called Urban Infill will be adopted. “Urban infill is defined as new development that is sited on vacant or undeveloped land within an existing community, and that is enclosed by other types of development.” (NLC, 2017) By doing this we can avoid being forced to extend the UDB zoning line as the urban infill will allow us to contain more residents.`}</p>
    <p>{`We must stop moving the UDB back, adopt Urban Infill, and create new zoning based on where the sea level is projected to rise.`}</p>
    <p>{`###Prong 2: Transportation`}</p>
    <p><em parentName="p">{`All images in this section were marked as ‘fair use’ meaning one can use them while respecting the fair use law.`}</em></p>
    <p>{`Since 1990 Miami Dade-Broward’s auto emissions (CO2 emissions from automobiles) have increased by 58% (DARTE, 2019). We have to create an effective, modern public transportation system that motivates citizens to stop using/buying cars and other automobiles. People need to utilize public transportation. I believe that public transportation is the key to a ‘moving city’. With fewer cars on the road, people will be able to move from place to place at a significantly faster rate.`}</p>
    <p>{`Although creating an effective public transportation system that uses buses and possibly an above or below ground metro/train system is expensive, these costs will be made back through jobs and the money made from the system.`}</p>
    <p>{`A suggested way to do this is an expansion of the ‘Metro Mover’ railway. The Metro Mover is a modern and very nice railway system. Its only downfall is that it doesn’t cover enough of the city.`}</p>
    <p>{`In this map, one can see that it covers the area around Downtown Miami and North and South of it. At first glance, this plan seems perfect. It seems to be a highly effective form of transportation.  Everyone likes it, so expanding it would seem like a full-proof way to make sure there will be people who use it, as it is already popular.This is completely incorrect, the expansion has been delayed for years because this is an unrealistic and impractical idea. It would cost an astronomical amount of taxpayers’ money that would most likely never be made back.`}</p>
    <p>{`Rather, we should invest the money into more practical solutions, such as a massive expansion to the bus system, including more active buses, and an extension of the areas covered by the system. Although buses are less popular than the MetroRail, this system is less expensive and significantly more practical. Furthermore, the MetroRail system would require the building of railways throughout the city, taking up valuable real-estate space and making it harder to do the previously mentioned Urban Infill strategy.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ef5264c7e097ce0ab1ab355c663c3fe3/212bf/image5.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "115.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABYBAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB7MJAqbwnZGv/xAAXEAEBAQEAAAAAAAAAAAAAAAABEAAR/9oACAEBAAEFAqtZ03//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAcEAADAAIDAQAAAAAAAAAAAAAAAREhMRBBYaH/2gAIAQEAAT8hcjybVO8nkLeV9G66TeFA/9oADAMBAAIAAwAAABAwEML/xAAYEQEAAwEAAAAAAAAAAAAAAAAAARExQf/aAAgBAwEBPxBOurf/xAAYEQEBAAMAAAAAAAAAAAAAAAAAAREhMf/aAAgBAgEBPxCJxJph/8QAGhABAQEBAQEBAAAAAAAAAAAAAREAITFRof/aAAgBAQABPxAdDK238MqVkOcyIj0Oap5s6xwngAF4mREde+7grQfmQhERPmN1bv/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image5",
            "title": "image5",
            "src": "/static/ef5264c7e097ce0ab1ab355c663c3fe3/212bf/image5.jpg",
            "srcSet": ["/static/ef5264c7e097ce0ab1ab355c663c3fe3/f93b5/image5.jpg 300w", "/static/ef5264c7e097ce0ab1ab355c663c3fe3/b4294/image5.jpg 600w", "/static/ef5264c7e097ce0ab1ab355c663c3fe3/212bf/image5.jpg 768w"],
            "sizes": "(max-width: 768px) 100vw, 768px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Buses, on the other hand, run on streets that are already built, all we would need to do is add more routes and buy more buses. It is significantly cheaper and creates more jobs.`}</p>
    <p>{`Still, buses are a flawed solution, they would also produce CO2 (although still significantly decreasing emissions). There is still one last solution I would like to suggest, BayLink. BayLink would connect MIA and the downtown MetroMover/Metro Rail stations. This would make it easier for tourists to access them. This solution would earn the cost back as tourists fund much of the Miami economy. The ideal transportation solution would be a combination of BayLink and the aforementioned increase to the bus system. The estimated cost is currently around 380 million according to MiamiBeachMonorail.com (u.d.) The reason for the steep price is because this would be a monorail, although it is true that monorails take up expensive real-estate space, it is worth it because the other proposal (a BayLink trolly system) would eat up space on the roads and likely lead to more traffic jams. Trollies are known for being slow and they also annoy drivers. The BayLink monorail system (BayLink) accompanied by a massive bus expansion is the best system for the Miami Dade-Broward public transportation expansion.`}</p>
    <p>{`###Prong 3: Energy`}</p>
    <p>{`According to miamibeachfl.gov the building sector of Miami accounts for roughly 50% of the city’s CO2 emissions (u.d.). The fastest way to combat this is by adapting the current way buildings within Miami get their energy.`}</p>
    <p>{`Florida and more specifically the Miami/Miami Beach area is famous for its sunshine. So much so that the state itself is called ‘The Sunshine State’. By installing solar panels on the roofs of buildings we can reduce energy bills, CO2 emissions. All the while, creating new jobs in one of the fastest-growing markets in America - solar power.`}</p>
    <p>{`Another way of energy can be harnessed through the ocean. As Miami is a coastal city it would be inefficient not to take advantage of this. There are two types of energy that you can produce using the ocean: thermal and mechanical. Thermal energy uses the warm surface temperatures of water to generate energy, while mechanical energy uses the flows of tides to generate energy, created by the Earth’s rotation and gravity from the moon. (Just Energy, U.D.).`}</p>
    <p>{`Wave energy is a consistent and predictable form of energy as it is easy to estimate the amount that will be produced. “This form of energy has an estimated ability to produce 2640 TWh/yr. (1 TWh/yr of energy can power around 93,850 average U.S. homes `}{`[...]`}{` annually)” (JE, 2020)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "894px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95df49c0cc161988a6b419f2d6d5f7e6/d98c0/image4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEDAv/EABUBAQEAAAAAAAAAAAAAAAAAAAED/9oADAMBAAIQAxAAAAGBJUhkYn//xAAaEAACAgMAAAAAAAAAAAAAAAABEgAhAhAR/9oACAEBAAEFAgipgYRfaY6//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Bqv/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AYf/xAAYEAACAwAAAAAAAAAAAAAAAAAAMQEgIf/aAAgBAQAGPwLRyOn/xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhMUFhof/aAAgBAQABPyFOgWF5ClSSJUo1UZ0j/9oADAMBAAIAAwAAABAA3//EABcRAQADAAAAAAAAAAAAAAAAAAEQETH/2gAIAQMBAT8QBlQ//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBvpD//xAAbEAEBAQACAwAAAAAAAAAAAAABEQAhcUFh0f/aAAgBAQABPxBIGExR5zMB7q+ZMF4nLUI6yhwjrKKqO//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "image4",
            "title": "image4",
            "src": "/static/95df49c0cc161988a6b419f2d6d5f7e6/d98c0/image4.jpg",
            "srcSet": ["/static/95df49c0cc161988a6b419f2d6d5f7e6/f93b5/image4.jpg 300w", "/static/95df49c0cc161988a6b419f2d6d5f7e6/b4294/image4.jpg 600w", "/static/95df49c0cc161988a6b419f2d6d5f7e6/d98c0/image4.jpg 894w"],
            "sizes": "(max-width: 894px) 100vw, 894px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><br parentName="p"></br>{`
`}{`(OWEL, 2020)`}</p>
    <p>{`###Conclusion`}</p>
    <p>{`Miami must begin its fight against climate change if we want to see the city survive the next few decades. As shown here, hurricanes will increase in power, and floods are getting more and more frequent. Furthermore, in fifty years Miami is likely to be flooded due to the sea level rise. To combat this, the Miami metropolitan area should adopt the three-pronged approach: new zoning, an improved public transportation system, and new, modern forms of energy to defend the city from these threats. This approach would decrease CO2 emissions and create new zoning regulations that will keep residents of Miami safe. If we do not begin our defense now, the city will be in extreme danger in the near future.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      